// styles.js

// Estilos para los enlaces de navegación
export const buttonLinkStyle = {
  color: "#333",
  textDecoration: "none",
  padding: "10px 15px",
  // backgroundColor: "#f0f0f0",
  borderRadius: "5px",
  transition: "background-color 0.3s ease",
  fontFamily: 'CenturyGothic', 
  fontWeight: "bold",
};

// Estilos para el encabezado
export const headerStyle = {
  height: "10vh",
  background: "#fff",
  color: "#333",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 20px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

// Contenedor de navegación
export const navContainerStyle = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
};

// Lista de navegación
export const navStyle = {
  display: "flex",
  justifyContent: "center",
  listStyle: "none",
  gap: "15px",
  margin: 0,
  padding: 0,
};

// Estilo del logo
export const logoStyle = {
  width: "50px",
  height: "50px",
};

// Estilos para el menú móvil
export const mobileMenuStyle = {
  position: "absolute",
  top: "70px",
  left: 0,
  width: "100%",
  backgroundColor: "#fff",
  zIndex: 1000,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

export const mobileNavStyle = {
  listStyle: "none",
  padding: 0,
  margin: 0,
};

export const mobileButtonLinkStyle = {
  display: "block",
  padding: "15px",
  textDecoration: "none",
  color: "#333",
  borderBottom: "1px solid #ddd",
};

// Estilos adicionales
export const floatingButtonStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  borderRadius: "50%",
  padding: "10px",
  zIndex: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "white",
};

export const menuStyle = {
  position: "fixed",
  bottom: "50px",
  left: "50%",
  transform: "translateX(-50%)",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  borderRadius: "10px",
  padding: "10px 20px",
  display: "flex",
  justifyContent: "center",
  gap: "20px",
  zIndex: 1000,
};

export const languageSelectorContainerStyle = {
  position: "relative",
};

export const selectedFlagStyle = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
};

export const dropdownStyle = {
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  zIndex: 1,
  borderRadius: "5px",
};

export const dropdownItemStyle = {
  display: "flex",
  alignItems: "center",
  padding: "8px 12px",
  cursor: "pointer",
};

export const dropdownItemHoverStyle = {
  transition: "background-color 0.2s ease-in-out",
  ":hover": {
    backgroundColor: "#f0f0f0",
  },
};

export const dropdownTextStyle = {
  marginLeft: "10px",
  fontSize: "16px",
  color: "#333",
};

export const flagStyle = {
  width: "30px",
  height: "30px",
};

import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function InfoCard({ handleCloseCard, infoContent }) {
  return (
    <div
      style={{
        position: "fixed",
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={handleCloseCard}
        style={{
          color: "white",
          position: "absolute",
          top: "10px",
          left: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ color: "white" }}>{infoContent}</div>
    </div>
  );
}

export default InfoCard;

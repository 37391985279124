import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { floatingButtonStyle } from "./styles";

const FloatingButton = ({ onDownload }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key.toLowerCase() === "c") {
        setVisible((prevVisible) => !prevVisible);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (!visible) return null;

  return (
    <Tooltip title="Download JSON">
      <div style={floatingButtonStyle} onClick={onDownload}>
        <IconButton style={{ color: "white" }}>
          <SaveIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default FloatingButton;

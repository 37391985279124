import React from "react";

function InfoContent1() {
  return (
    <div>
      <h2>Información del Contenido 1</h2>
      <p>Este es el contenido del archivo InfoContent1.</p>
    </div>
  );
}
export default InfoContent1;

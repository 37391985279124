import React from "react";
import SecondLevelNodes from "./SecondLevelNodes";
import * as THREE from "three";

const FirstLevelNode = ({
  viewport,
  videoTextures,
  onClick,
  selectedPath,
  selectedMeshRef,
}) => {
  const handleNodeClick = (e) => {
    e.stopPropagation();

    onClick({
      ...viewport,
      path: viewport.id_vp,
      videoElement: null,
      type: "firstLevel",
    });
  };

  return (
    <>
      <mesh
        ref={selectedPath === viewport.id_vp ? selectedMeshRef : null}
        position={viewport.position}
        rotation={viewport.rotation}
        scale={viewport.scale}
        onClick={handleNodeClick} // Usando la función que maneja la transformación relativa
      >
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color={viewport.color} />
      </mesh>
      {viewport.elements &&
        viewport.elements.map((element) => (
          <SecondLevelNodes
            key={element.id}
            element={element}
            parentId={viewport.id_vp}
            parentPosition={viewport.position}
            parentRotation={viewport.rotation}
            videoTextures={videoTextures}
            onClick={onClick}
          />
        ))}
    </>
  );
};

export default FirstLevelNode;

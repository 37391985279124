import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import MoveIcon from "@mui/icons-material/OpenWith";
import RotateIcon from "@mui/icons-material/RotateRight";
import DetailsIcon from "@mui/icons-material/Info";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CloseIcon from "@mui/icons-material/Close";
import { menuStyle } from "./styles";

const FloatingMenu = ({
  isVideoPlaying,
  handleMoveToggle,
  handleRotateToggle,
  handleDetailsClick, // Nuevo handler para el botón de detalles
  handleVideoPlayPause,
  handleClose,
}) => {
  return (
    <div style={menuStyle}>
      <Tooltip title="Move">
        <IconButton onClick={handleMoveToggle} style={{ color: "white" }}>
          <MoveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Rotate">
        <IconButton onClick={handleRotateToggle} style={{ color: "white" }}>
          <RotateIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Details">
        <IconButton onClick={handleDetailsClick} style={{ color: "white" }}>
          <DetailsIcon />
        </IconButton>
      </Tooltip>
      {isVideoPlaying !== null && (
        <Tooltip title={isVideoPlaying ? "Pause" : "Play"}>
          <IconButton onClick={handleVideoPlayPause} style={{ color: "white" }}>
            {isVideoPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Close">
        <IconButton onClick={handleClose} style={{ color: "white" }}>
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default FloatingMenu;

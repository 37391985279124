// AboutUs.js
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "./LanguageContext";
import "./AboutUs.css"; // Archivo CSS para los estilos
import texts from "./texts.json"; // Importamos el archivo JSON con los textos y datos

const AboutUs = () => {
  const { selectedLanguage } = useContext(LanguageContext); // Usamos el contexto para obtener el idioma seleccionado
  const [content, setContent] = useState({});

  useEffect(() => {
    // Cargar los textos y datos en función del idioma seleccionado
    setContent(texts[selectedLanguage]);
  }, [selectedLanguage]);

  return (
    <div className="about-us-container">
      {/* Cambiamos el estilo del texto dependiendo si el idioma es árabe (RTL) o no */}
      <div style={selectedLanguage === "ar" ? { direction: "rtl" } : {}}>
        <h1 className="about-us-title">{content.title}</h1>
        <p>
          <strong>{content.collective}</strong>
          <br />
          {content.description1}
        </p>
        <p>{content.description2}</p>
        <p>{content.description3}</p>
      </div>

      <h2 className="table-title">{content.artistsTitle}</h2>

      <table className="artist-table">
        <thead>
          <tr>
            <th>{content.tableHeaders?.name}</th>
            <th>{content.tableHeaders?.link}</th>
          </tr>
        </thead>
        <tbody>
          {content.tableData?.map((person, index) => (
            <tr key={index}>
              <td>{person.name}</td>
              <td>
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  {person.link ? person.link : "N/A"}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AboutUs;

import React, { useRef, useEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import FirstLevelNode from "./FirstLevelNode";
import GradientBackground from "./GradientBackground"; // Importa el componente
import * as THREE from "three";

const Scene = ({
  viewports,
  selectedPath,
  transformMode,
  targetPosition, // Recibiendo targetPosition
  targetLookAt, // Recibiendo targetLookAt
  selectedObjectTransform,
  onClick,
  orbitControlsRef,
}) => {
  const { camera } = useThree();
  const selectedMeshRef = useRef(null);

  const videoTextures = useMemo(() => {
    const textures = {};

    viewports.forEach((viewport) => {
      viewport.elements.forEach((element) => {
        if (element.type === "video") {
          const video = document.createElement("video");
          video.src = element.url;
          video.crossOrigin = "anonymous";
          video.loop = true;
          video.muted = true;
          video.playsInline = true;
          video.autoplay = element.autoplay;

          const texture = new THREE.VideoTexture(video);
          texture.minFilter = THREE.LinearFilter;
          texture.magFilter = THREE.LinearFilter;
          texture.format = THREE.RGBAFormat;

          textures[element.url] = texture;
        }
      });
    });

    return textures;
  }, [viewports]);

  useEffect(() => {
    if (selectedMeshRef.current) {
      selectedMeshRef.current.position.copy(selectedObjectTransform.position);
      selectedMeshRef.current.rotation.copy(selectedObjectTransform.rotation);
    }
  }, [selectedObjectTransform]);

  return (
    <>
      {/* Agrega el GradientBackground con los colores deseados */}
      <GradientBackground
        colors={[
          "#243D87", // color1 - Esquina inferior izquierda
          "#624393", // color2 - Esquina inferior derecha
          "#6BC6E1", // color3 - Esquina superior derecha
          "#E53C8D", // color4 - Esquina superior izquierda
        ]}
      />

      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      {/* <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -1, 0]}>
        <planeBufferGeometry args={[100, 100]} />
        <meshStandardMaterial color="gray" />
      </mesh> */}

      {viewports.map((viewport) => (
        <FirstLevelNode
          key={viewport.id_vp}
          viewport={viewport}
          videoTextures={videoTextures}
          onClick={onClick}
          selectedPath={selectedPath}
          selectedMeshRef={
            selectedPath === viewport.id_vp ? selectedMeshRef : null
          }
        />
      ))}

      <OrbitControls ref={orbitControlsRef} />
    </>
  );
};

export default Scene;

import React from "react";

function InfoContent2() {
  return (
    <div>
      <h2>Información del Contenido 2</h2>
      <p>Este es el contenido del archivo InfoContent2.</p>
    </div>
  );
}
export default InfoContent2;

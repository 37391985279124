import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { menuStyle } from "./styles";

const NavigationBar = ({ viewports, onNavigate }) => {
  return (
    <div style={menuStyle}>
      {viewports.map((viewport) => (
        <Tooltip key={viewport.id_vp} title={`${viewport.label}`}>
          <IconButton
            onClick={() => {
              console.log(`Navigating to viewport: ${viewport.id_vp}`);
              onNavigate(viewport.id_vp);
            }}
            style={{ color: "white" }}
          >
            <img 
              src={viewport.icon} // La ruta de la imagen personalizada en formato PNG
              style={{ width: 75, height: 75 }} // Ajusta el tamaño según tus necesidades
            />
          </IconButton>
        </Tooltip>
      ))}
    </div>
  );
};

export default NavigationBar;

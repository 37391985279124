import React from "react";
import { Text, Image, Plane } from "@react-three/drei";
import * as THREE from "three";

const SecondLevelNodes = ({
  element,
  parentId,
  parentPosition,
  parentRotation,
  videoTextures,
  onClick,
}) => {
  const handleElementClick = (event, videoElement, id) => {
    event.stopPropagation();

    //onClick(parentId+"/"+id, videoElement);
    onClick({
      ...element,
      id_vp: parentId,
      path: parentId + "/" + id,
      videoElement: videoElement,
      type: "secondLevel",
    });

    if (videoElement) {
      const isPlaying = !videoElement.paused;
      videoElement[isPlaying ? "pause" : "play"]();
    }
  };

  const finalPosition = [
    parentPosition[0] + element.position[0],
    parentPosition[1] + element.position[1],
    parentPosition[2] + element.position[2],
  ];

  const finalRotation = [
    parentRotation[0] + element.rotation[0],
    parentRotation[1] + element.rotation[1],
    parentRotation[2] + element.rotation[2],
  ];

  switch (element.type) {
    case "text":
      return (
        <Text
          key={element.id}
          position={finalPosition}
          rotation={finalRotation}
          fontSize={element.fontSize || 0.1}
          color={element.color || "white"}
          onClick={(e) => handleElementClick(e, null, element.id)}
        >
          {element.content}
        </Text>
      );

    case "img":
      return (
        <Image
          key={element.id}
          url={element.url}
          position={finalPosition}
          rotation={finalRotation}
          scale={element.scale || [0.5, 0.5, 0.5]}
          onClick={(e) => handleElementClick(e, null, element.id)}
        />
      );

    case "video":
      const videoTexture = videoTextures ? videoTextures[element.url] : null;
      if (!videoTexture) {
        console.warn(`Video texture not found for URL: ${element.url}`);
        return null;
      }
      return (
        <Plane
          key={element.id}
          args={[element.scale[0], element.scale[1]]}
          position={finalPosition}
          rotation={finalRotation}
          onClick={(e) => handleElementClick(e, videoTexture.image, element.id)}
        >
          <meshStandardMaterial
            attach="material"
            map={videoTexture}
            side={THREE.DoubleSide}
          />
        </Plane>
      );

    default:
      return (
        <mesh
          key={element.id}
          position={finalPosition}
          rotation={finalRotation}
          scale={element.scale || [0.5, 0.5, 0.5]}
          onClick={(e) => handleElementClick(e, null, element.id)}
        >
          <boxGeometry args={[0.5, 0.5, 0.5]} />
          <meshStandardMaterial color={element.color || "gray"} />
        </mesh>
      );
  }
};

export default SecondLevelNodes;

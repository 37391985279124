// index.js
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "./styles.css"; // Importamos el archivo CSS
import App from "./Components2/App";
import AboutUs from "./Components2/AboutUs";
import ContactUs from "./Components2/ContactUs";
import {
  headerStyle,
  navStyle,
  navContainerStyle,
  logoStyle,
  buttonLinkStyle,
  mobileMenuStyle,
  mobileNavStyle,
  mobileButtonLinkStyle,
} from "./Components2/styles";
import { LanguageProvider } from "./Components2/LanguageContext";
import LanguageSelector from "./Components2/LanguageSelector";

function AppHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  // Detectar el tamaño de la pantalla para habilitar/ocultar el menú hamburguesa
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Limpiar el evento cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header style={headerStyle}>
      <img src="/logo-framed.png" alt="Logo" style={logoStyle} />

      {/* Botón de menú hamburguesa */}
      {isMobile && (
        <div className="hamburger-menu" onClick={toggleMobileMenu}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      )}

      {/* Navegación para pantallas grandes */}
      {!isMobile && (
        <div style={navContainerStyle} className="nav-container">
          <ul style={navStyle}>
            <li>
              <Link to="/" style={buttonLinkStyle}>
                MINDMAPS
              </Link>
            </li>
            <li>
              <Link to="/aboutus" style={buttonLinkStyle}>
                ABOUT US
              </Link>
            </li>
            <li>
              <Link to="/contactus" style={buttonLinkStyle}>
                CONTACT US
              </Link>
            </li>
            <li>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      )}

      {/* Menú móvil */}
      {isMobile && mobileMenuOpen && (
        <div style={mobileMenuStyle} className="mobile-menu">
          <ul style={mobileNavStyle}>
            <li>
              <Link
                to="/"
                style={mobileButtonLinkStyle}
                onClick={toggleMobileMenu}
              >
                MINDMAPS
              </Link>
            </li>
            <li>
              <Link
                to="/aboutus"
                style={mobileButtonLinkStyle}
                onClick={toggleMobileMenu}
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                to="/contactus"
                style={mobileButtonLinkStyle}
                onClick={toggleMobileMenu}
              >
                CONTACT US
              </Link>
            </li>
            <li>
              <LanguageSelector />
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LanguageProvider>
    <Router>
      <AppHeader />
      <div style={{ height: "90vh" }}>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
      </div>
    </Router>
  </LanguageProvider>
);

import React, { useState, useEffect } from "react";
import { IconButton, Slider, Input, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import * as THREE from "three";

const TransformControlsPanel = ({
  transform,
  onTransformChange,
  handleClose,
}) => {
  const [position, setPosition] = useState([
    transform.position.x,
    transform.position.y,
    transform.position.z,
  ]);
  const [rotation, setRotation] = useState([
    THREE.MathUtils.radToDeg(transform.rotation.x),
    THREE.MathUtils.radToDeg(transform.rotation.y),
    THREE.MathUtils.radToDeg(transform.rotation.z),
  ]);

  useEffect(() => {
    setPosition([
      transform.position.x,
      transform.position.y,
      transform.position.z,
    ]);
    setRotation([
      THREE.MathUtils.radToDeg(transform.rotation.x),
      THREE.MathUtils.radToDeg(transform.rotation.y),
      THREE.MathUtils.radToDeg(transform.rotation.z),
    ]);
  }, [transform]);

  const handlePositionChange = (index, value) => {
    const newPosition = [...position];
    newPosition[index] = value;
    setPosition(newPosition);
    onTransformChange(newPosition, rotation);
  };

  const handleRotationChange = (index, value) => {
    const newRotation = [...rotation];
    newRotation[index] = value;
    setRotation(newRotation);
    onTransformChange(position, newRotation);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "10%",
        right: "5%",
        width: "300px",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderRadius: "10px",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1001,
      }}
    >
      <IconButton
        onClick={handleClose}
        style={{
          color: "white",
          position: "absolute",
          top: "10px",
          right: "10px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <h3 style={{ color: "white" }}>Transform Controls</h3>

      {["X", "Y", "Z"].map((axis, index) => (
        <div key={`pos-${axis}`} style={{ width: "100%", margin: "10px 0" }}>
          <Tooltip title={`Position ${axis}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "white", width: "30px" }}>{axis}:</span>
              <Slider
                value={position[index]}
                min={position[index] - 3}
                max={position[index] + 3}
                step={0.01}
                onChange={(e, value) => handlePositionChange(index, value)}
                style={{ flexGrow: 1, marginRight: "10px" }}
              />
              <Input
                value={position[index]}
                onChange={(e) =>
                  handlePositionChange(index, parseFloat(e.target.value))
                }
                style={{ color: "white", width: "50px" }}
              />
            </div>
          </Tooltip>
        </div>
      ))}

      {["X", "Y", "Z"].map((axis, index) => (
        <div key={`rot-${axis}`} style={{ width: "100%", margin: "10px 0" }}>
          <Tooltip title={`Rotation ${axis}`}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ color: "white", width: "30px" }}>{axis}:</span>
              <Slider
                value={rotation[index]}
                min={rotation[index] - 45}
                max={rotation[index] + 45}
                step={1}
                onChange={(e, value) => handleRotationChange(index, value)}
                style={{ flexGrow: 1, marginRight: "10px" }}
              />
              <Input
                value={rotation[index]}
                onChange={(e) =>
                  handleRotationChange(index, parseFloat(e.target.value))
                }
                style={{ color: "white", width: "50px" }}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default TransformControlsPanel;

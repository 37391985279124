// ContactUs.js
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "./LanguageContext";
import "./ContactUs.css"; // Archivo CSS para estilos
import texts from "./texts.json"; // Importamos el JSON

const ContactUs = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const [content, setContent] = useState({});

  useEffect(() => {
    // Cargar los datos de contacto en función del idioma seleccionado
    setContent(texts[selectedLanguage]?.contact || {});
  }, [selectedLanguage]);

  return (
    <div className="contact-us-container">
      <h1 className="contact-us-title">{content.title}</h1>
      <p className="contact-email">
        {content.emailLabel} <strong>dahaleezcollective@gmail.com</strong>
      </p>
      <table className="contact-table">
        <thead>
          <tr>
            <th>{content.tableHeaders?.artist}</th>
            <th>{content.tableHeaders?.mobile}</th>
            <th>{content.tableHeaders?.email}</th>
          </tr>
        </thead>
        <tbody>
          {content.contactData?.map((contact, index) => (
            <tr key={index}>
              <td>{contact.name}</td>
              <td>{contact.mobile}</td>
              <td>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactUs;

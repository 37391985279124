import React, { useContext, useState } from "react";
import {
  languageSelectorContainerStyle,
  selectedFlagStyle,
  dropdownStyle,
  dropdownItemStyle,
  dropdownTextStyle,
  flagStyle,
  dropdownItemHoverStyle, // Nuevo estilo para hover
} from "./styles";
import { LanguageContext } from "./LanguageContext"; // Importamos el contexto

function LanguageSelector() {
  const { selectedLanguage, handleLanguageChange } =
    useContext(LanguageContext); // Usamos el contexto
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const languageOptions = {
    en: { name: "English", flag: "/lang_en.png" },
    ar: { name: "العربية", flag: "/lang_ar.png" }, // Asegúrate de que la bandera árabe está disponible en tu proyecto
  };

  return (
    <div style={languageSelectorContainerStyle}>
      <div style={selectedFlagStyle} onClick={toggleDropdown}>
        <img
          src={languageOptions[selectedLanguage].flag}
          alt={languageOptions[selectedLanguage].name}
          style={flagStyle}
        />
      </div>
      {dropdownVisible && (
        <div style={dropdownStyle}>
          {Object.keys(languageOptions).map((lang) => (
            <div
              key={lang}
              style={{ ...dropdownItemStyle, ...dropdownItemHoverStyle }} // Aplicamos hover
              onClick={() => {
                handleLanguageChange(lang);
                setDropdownVisible(false); // Ocultar el menú desplegable después de seleccionar
              }}
            >
              <img
                src={languageOptions[lang].flag}
                alt={languageOptions[lang].name}
                style={flagStyle}
              />
              <span style={dropdownTextStyle}>
                {languageOptions[lang].name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
